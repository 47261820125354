import React, {useEffect, useState} from "react";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import axios from "axios";

const SignIn = () => {
  const title=global.serviceTitle;
  const [loginId, setLoginId] = useState(global.service =='bird_alarm' || global.service =='sdf' ? 'admin' : null);
  const [password, setPassword] = useState(global.service =='bird_alarm' || global.service =='sdf' ? 'djemals1' : null);
  const [isLogin, setIsLogin] = useState(false);
  const colorUrl= global.dbApiUrl + "/api/react/epidMap/selectEpidMapUserBirdColor.json?user_nm="+loginId;

  /** service check 자동 로그인 */
  const search = window.location.search
  const param = new URLSearchParams(search);
  const key = param.get("key")
  useEffect(()=>{
    console.log('document.referrer=' + document.referrer +  ' / key=' + key);
    if(key == "PG36p6TQTSBKRc1mc6RrRC4dn5lX27mh3xxLXBSiqwpCZL7Ns18IVB5xrg6ZDjMj" || key == "CYd5bZy0unKSoZVtorRVcSSRn3OvGX1CNCbs6MJ4an-iXu3yEXWvJCuY7TVy97Pm"){
      //if(document.referrer == "https://disasys.ezfarm.co.kr/sso/" || document.referrer == "http://172.30.1.250:3001/"){
      // referrer가 https인데 우리 사이트는 http 라서 referrer를 가져올 수 없음
      if(document.referrer == "" || document.referrer == "http://172.30.1.250:3001/"){
        console.log('sso 성공');
        loginSubmit();
      }else{
        window.history.back();
      }
    }
    if(global.service =='bird_alarm' || global.service =='sdf') {
      loginSubmit();
    }
  }, [])
  
  /** 정상로그인 시 컬러코드 */
  useEffect(()=> {
    if(isLogin) colorData(colorUrl);
  }, [isLogin])

  const loginEvt = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if(loginId === null) {
      alert('아이디를 입력해 주세요.');
      return;
    }
    if(password == null) {
      alert('암호를 입력해 주세요.');
      return;
    }
    loginSubmit();
  }

  const loginSubmit = () =>{
    let signInUrl = global.signInUrl+'/selectHpaiMenuList?id=' + loginId + '&password=' + password + '&userSe=GNR' + '&siteGbn=' + global.service;
    if(key == "PG36p6TQTSBKRc1mc6RrRC4dn5lX27mh3xxLXBSiqwpCZL7Ns18IVB5xrg6ZDjMj"){
      signInUrl = global.signInUrl+'/selectHpaiMenuList?id=' + "ezfarm_01" + '&password=' + "ezfarm01" + '&userSe=GNR' + '&siteGbn=' + global.service;
    }
    if (key == 'CYd5bZy0unKSoZVtorRVcSSRn3OvGX1CNCbs6MJ4an-iXu3yEXWvJCuY7TVy97Pm') {
      signInUrl = global.signInUrl+'/selectHpaiMenuList?id=' + "ezfarm_02" + '&password=' + "ezfarm02" + '&userSe=GNR' + '&siteGbn=' + global.service;
    }
    login_fetch(signInUrl);
  }

  const login_fetch = async (url) => {
    const result = await axios(url,  {withCredentials: true});
    const response = result.data;
    const message = response.message;       // 로그인 실패 메시지
    
    if(message != null && message != '') {
      alert(message);
      return;
    }
    
    const menuList = response.menuList;     // 메뉴 목록
    const loginVO = response.loginVO;       // 로그인 정보
    const sessionID = response.sessionID;   // Session Id
    const hpaiRole = response.hpaiRole;     // 권한정보
    const today = new Date();
    if(menuList == null || menuList == '') { // 메뉴 정보 없을 시
      alert('접속권한이 없습니다. \n관리자에게 문의 바랍니다.');
      return;
    } else {  //정상로그인
      setIsLogin(!isLogin);
      localStorage.setItem('menuList', JSON.stringify(menuList))  // 메뉴목록
      localStorage.setItem('userInfo', JSON.stringify({id: loginVO.id, name: loginVO.name, email: loginVO.email, token: sessionID, passwordEnc:loginVO.password}))  // 사용자 정보(ID, 이름, 이메일, token, password)
      localStorage.setItem('hpaiRole', JSON.stringify(hpaiRole))  // 사용자 권한정보
      localStorage.setItem('isSignin', 'true')  // 로그인상태 true
      localStorage.setItem('sessionChk', today)  // 로그인상태 true
      loginInsertHist(loginVO.id)
    }
  }

  const loginInsertHist = (loginId) => {
    const meta = {
      "loginId" : loginId,
      "serviceDomain" : global.service
    }
    
    /* global.dmApiUrl */
    axios.post(global.dmApiUrl+'/loginHists/insert' , meta)
    .then((response) => {
      console.log("response",response)
      if(key == "PG36p6TQTSBKRc1mc6RrRC4dn5lX27mh3xxLXBSiqwpCZL7Ns18IVB5xrg6ZDjMj"){
        window.location.href = "http://hpai.kware.co.kr/hpai/";
      }
    })
  }
  
  const colorData = async (url) => {
    const result = await axios(url);
    const response = result.data;
    localStorage.setItem('user', loginId);
    localStorage.setItem('koecoColor', JSON.stringify(response.bird_koeco));
    localStorage.setItem('moveBankColor', JSON.stringify(response.bird_movebank));
    localStorage.setItem('migColor', JSON.stringify(response.mig));
    localStorage.setItem('birdTypeColor', JSON.stringify(response.bird_type));
    localStorage.setItem('sidoColor', JSON.stringify(response.sido));
    window.location.href= "/";
  }
  return (
    <div>
      {global.service =='bird_alarm' || global.service =='sdf' || key == "PG36p6TQTSBKRc1mc6RrRC4dn5lX27mh3xxLXBSiqwpCZL7Ns18IVB5xrg6ZDjMj" || key == 'CYd5bZy0unKSoZVtorRVcSSRn3OvGX1CNCbs6MJ4an-iXu3yEXWvJCuY7TVy97Pm' ? "" :  <React.Fragment>
          <div className="text-center mt-4">
            <h2>{title}</h2>
            <p className="lead">로그인이 필요합니다</p>
          </div>
          <Card>
            <CardBody>
              <div className="m-sm-4">
                <Form id="form" onSubmit={loginEvt}>
                  <FormGroup>
                    <Label>아이디</Label>
                    <Input
                      bsSize="lg"
                      type="text"
                      name="loginId"
                      placeholder="아이디를 입력하세요"
                      onChange={(e) => {setLoginId(e.target.value)}}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>암호</Label>
                    <Input
                      bsSize="lg"
                      type="password"
                      name="password"
                      placeholder="암호를 입력하세요"
                      onChange={(e) => {setPassword(e.target.value)}}
                    />
                  </FormGroup>
                  <div className="text-center mt-3">
                      <Button type={'submit'} color="primary" size="lg">로그인</Button>
                  </div>
                </Form>
              </div>
            </CardBody>
          </Card>
      </React.Fragment>}
    </div> 
  )
}

export default SignIn;
/////////////////////////////////////////////////////////////////////////////


// import React, {useContext, useEffect} from "react";
// import AuthContext from '../../components/AuthContext';
// import UserContext from '../../components/UserContext'
// import {
//   Button,
//   Card,
//   CardBody,
//   Form,
//   FormGroup,
//   Label,
//   Input
// } from "reactstrap";
// import crypto from 'crypto';

// const UserContextWrapper = () => {
//   const user = useContext(UserContext)
// }

// class SignIn extends React.Component {
//   static contextType = AuthContext;
//   //authContext = useContext(AuthContext);
  
//   constructor(props) {
//     super(props);
//     if(!props.authContext) {
//       //props.authContext = this.context;
//     }
//     this.state = {isLogin: false};    
//     global.service =='rnd'? this.state.loginId ='admin': this.state.loginId = ''
//     global.service =='rnd'? this.state.password ='djemals1': this.state.password = ''
//     props.authContext.actions.setIsSignin(true);
//     this.handleClick = this.handleClick.bind(this);
//   }

//   async componentDidMount() {
//     this.context.state.isSignin = true;
//     this.context.actions.setIsSignin(true);
//     const user = this.state.loginId;
//     const url= global.dbApiUrl + "/api/react/epidMap/selectEpidMapUserBirdColor.json?user_nm="+user;
//     const response = await fetch(url).then(function(res){ return res.json(); });
//     localStorage.setItem('user', user);
//     localStorage.setItem('koecoColor', JSON.stringify(response.bird_koeco));
//     localStorage.setItem('moveBankColor', JSON.stringify(response.bird_movebank));
//     localStorage.setItem('migColor', JSON.stringify(response.mig));
//     localStorage.setItem('birdTypeColor', JSON.stringify(response.bird_type));
//     if(global.service ==='rnd'){
//       this.fetchData();
//     }
//   }

//   async handleClick(e) {
//     e.preventDefault();
//     e.stopPropagation();
//     this.context.actions.setIsSignin(true);
//     if(this.state.loginId == null || this.state.loginId == '') {
//       alert('아이디를 입력해 주세요.');
//       return;
//     }
//     if(this.state.password == null || this.state.password == '') {
//       alert('암호를 입력해 주세요.');
//       return;
//     }
    
//     this.fetchData();
//   }
  
//   fetchData = function() {
//     let id = this.state.loginId;
//     let password = this.state.password;
//     let authenticated = false;
//     let signInUrl = global.signInUrl+'/selectHpaiMenuList?id=' + id + '&password=' + password + '&userSe=GNR' + '&siteGbn=' + global.service;
//     //signInUrl = 'http://localhost:8000/menus.json';
//     fetch(signInUrl, { method: 'post' ,credentials: 'include'})
//     //fetch(signInUrl)
//     .then(response => response.json())
//     .then(modelAndView => {
//       var menuList = modelAndView.menuList;     // 메뉴 목록
//       var loginVO = modelAndView.loginVO;       // 로그인 정보
//       var sessionID = modelAndView.sessionID;   // Session Id
//       var hpaiRole = modelAndView.hpaiRole;     // 권한정보
//       var message = modelAndView.message;       // 로그인 실패 메시지
//       // 로그인 실패 시
//       if(message != null && message != '') {
//         alert(message);
//         return;
//       }
//       // 메뉴 정보 없을 시
//       else if(menuList == null || menuList == '') {
//         alert('접속권한이 없습니다. \n관리자에게 문의 바랍니다.');
//         return;
//       }
//       // 정상 접속
//       else {
//         localStorage.setItem('user', id);
//         localStorage.setItem('menuList', JSON.stringify(menuList))  // 메뉴목록
//         localStorage.setItem('userInfo', JSON.stringify({id: loginVO.id, name: loginVO.name, email: loginVO.email, token: sessionID, passwordEnc:loginVO.password}))  // 사용자 정보(ID, 이름, 이메일, token, password)
//         localStorage.setItem('hpaiRole', JSON.stringify(hpaiRole))  // 사용자 권한정보
//         localStorage.setItem('isSignin', 'true')  // 로그인상태 true
//         authenticated = true;
//         this.setState({isLogin : true});
//         window.location.href= "/";
//       }
//     })
//     .catch(error => console.error('Error!!!! :::::::::', error))
//   }

//   render() {
//     const title=global.serviceTitle;
//     return (
//       <div>
//         {global.service =='rnd'? "" :  <React.Fragment>
//             <div className="text-center mt-4">
//               <h2>{title}</h2>
//               <p className="lead">로그인이 필요합니다</p>
//             </div>
//             <Card>
//               <CardBody>
//                 <div className="m-sm-4">
//                   <Form id="form" onSubmit={this.handleClick}>
//                     <FormGroup>
//                       <Label>아이디</Label>
//                       <Input
//                         bsSize="lg"
//                         type="text"
//                         name="loginId"
//                         placeholder="아이디를 입력하세요"
//                         onChange={(e) => {this.setState({loginId: e.target.value})}}
//                       />
//                     </FormGroup>
//                     <FormGroup>
//                       <Label>암호</Label>
//                       <Input
//                         bsSize="lg"
//                         type="password"
//                         name="password"
//                         placeholder="암호를 입력하세요"
//                         onChange={(e) => {this.setState({password: e.target.value})}} 
//                       />
//                     </FormGroup>
//                     <div className="text-center mt-3">
//                         <Button type="submit" color="primary" size="lg">
//                           로그인
//                         </Button>
//                     </div>
//                   </Form>
//                 </div>
//               </CardBody>
//             </Card>
//         </React.Fragment>}
//       </div> 
//     )
//   }
// }

// export default SignIn;