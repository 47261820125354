import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

import './components/Global.js';

/*
class MyClass extends React.Component {
    render() {
        return (<font>aaa한글도?</font>)
    }
}

ReactDOM.render(<MyClass />, document.getElementById("root"));
*/
 if((window.location.href.indexOf('localhost') < 0 && window.location.href.indexOf('172.30.1.250') < 0) && global.service == 'rnd'&& window.location.pathname == "/") {
    window.location.href = window.location.origin+"/hpai/";
}  else {
    ReactDOM.render(<App />, document.getElementById("root"));
}