import React from "react";

import Main from "../components/Main_NewLayout";
import TopNav from "../components/Navbar_MigratoryBird";
import Wrapper from "../components/Wrapper";

const TopMenu = ({ children }) => {
    
    return (
        <React.Fragment>
            <TopNav />
            <Wrapper>
                <Main>
                    {children}
                </Main>
            </Wrapper>
        </React.Fragment>
    );
}
export default TopMenu;
