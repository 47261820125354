import React from "react";
import { connect } from "react-redux";
import { toggleSidebar } from "../redux/actions/sidebarActions";
import { Link } from 'react-router-dom';
//import main_logo_new_2 from '../assets/img/main_logo_new_2.png'
import logo_bird from '../assets/img/Top_Logos_bird.png'
import logo_cdr from '../assets/img/Top_Logos_cdr.png'
import logo_qz from '../assets/img/Top_Logos_qz.png'
import logo_rnd from '../assets/img/Top_Logos_rnd.png'

import analysis from '../assets/img/icon/analysis.png'
import bird_move from '../assets/img/icon/bird_move.png'
import data_mart from '../assets/img/icon/data_mart.png'
import navi_cost from '../assets/img/icon/navi_cost.png'
import disease_info from '../assets/img/icon/disease_info.png'
import * as SessionChk from "../pages/auth/SessionChk";
import './link.css';
import {
  Row,
  Col,
  Collapse,
  Navbar,
  Nav,
  NavbarBrand,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import {
  Settings,
  User,
} from "react-feather";


let logo_image = logo_rnd;
switch(global.service) {
	case 'rnd':
		logo_image = logo_rnd;
		break;
	case 'bird':
		logo_image = logo_bird;
		break;
	case 'cdr':
		logo_image = logo_cdr;
		break;
	case 'qz':
		logo_image = logo_qz;
		break;
	default : ;
}
const NavbarComponent = ({ dispatch }) => {
  SessionChk.chk();  
  return (
    <Navbar color="#082a4f" light expand style={{background: "#082a4f",height: "84px"}}>       
       <NavbarBrand href="/oie/hpai/overview" style={{padding:"0 0 0 0"}} >
        <img src={logo_image} /* style={{width:"20vw", resizeMode: 'cover'}} *//> {/* 브라우저 크기가 줄어들 시 같이 줄어들도록 css 수정 */}
        </NavbarBrand> 
        <Collapse navbar>
        {global.service == 'rnd' ?
        <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink href="/hpai/detection" style={{float:'left', color: '#ffffff'}} onMouseOut={(e) => {e.target.style.color="#ffffff"}} onMouseOver={(e) => {e.target.style.color="#32f8ff"}}>
              <img src={data_mart} style={{float:"left"}}/>
              유입감시
              </NavLink>
            </NavItem>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <NavItem >
              <NavLink href="/hpai/condition" style={{float:'left', color: '#ffffff'}} onMouseOut={(e) => {e.target.style.color="#ffffff"}} onMouseOver={(e) => {e.target.style.color="#32f8ff"}}>
              <img src={data_mart} style={{float:'left'}} />
                발생현황</NavLink>
            </NavItem>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <NavItem >
              <NavLink href="/hpai/prediction" style={{float:'left', color: '#ffffff'}} onMouseOut={(e) => {e.target.style.color="#ffffff"}} onMouseOver={(e) => {e.target.style.color="#32f8ff"}}>
              <img src={data_mart} style={{float:'left'}} />
                확산예측</NavLink>
            </NavItem>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <NavItem >
              <NavLink href="/oie/hpai/overview" style={{float:'left', color: '#32f8ff'}}>
              <img src={data_mart} style={{float:'left' , color:"#32f8ff"}} />
                통합분석</NavLink>
            </NavItem>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <NavItem >
              <NavLink href="/hpai/analysis" style={{float:'left', color: '#ffffff'}} onMouseOut={(e) => {e.target.style.color="#ffffff"}} onMouseOver={(e) => {e.target.style.color="#32f8ff"}}>
              <img src={analysis} style={{float:'left'}} />
                공간분석</NavLink>
            </NavItem>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <NavItem >
              <NavLink href="https://disasys.ezfarm.co.kr/analysis/costanalysis/hpai?kware=true" style={{float:'left', color: '#ffffff'}} onMouseOut={(e) => {e.target.style.color="#ffffff"}} onMouseOver={(e) => {e.target.style.color="#32f8ff"}}>
              <img src={navi_cost} style={{float:'left'}} />
                비용분석</NavLink>
            </NavItem>
        </Nav> : ""}
        {global.service != 'rnd' ?
        <Nav className="ml-auto" navbar>
          <UncontrolledDropdown nav inNavbar>
            <span className="d-inline-block d-sm-none">
              <DropdownToggle nav caret>
                <Settings size={18} className="align-middle" />
              </DropdownToggle>
            </span>
            <span className="d-none d-sm-inline-block">
              <DropdownToggle nav caret>
                <span className="text-light" style={{fontSize:'14px'}}>{JSON.parse(localStorage.getItem('userInfo')).name}</span>
              </DropdownToggle>
            </span>
            <DropdownMenu right>
              <DropdownItem onClick={() => {window.location.href='/auth/sign-out'}}>로그아웃</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        :""}
      </Collapse>
    </Navbar>
  );
};

{/* // #550 역학지도에 상단 메뉴 숨김 처리
    <Nav className="mr-auto" navbar>
        <NavItem >
            <img src={bird_move} style={{float:'left'}} />
          <NavLink href="/" style={{float:'left', color: 'white'}}>유입 감시</NavLink>
        </NavItem>
        <NavItem >
            <img src={disease_info} style={{float:'left'}} />
          <NavLink href="/" style={{float:'left', color: 'white'}}>확산 예측</NavLink>
        </NavItem>
        <NavItem >
            <img src={analysis} style={{float:'left'}} />
          <NavLink href="/" style={{float:'left', color: 'white'}}>역학분석도구</NavLink>
        </NavItem>
        <NavItem >
            <img src={data_mart} style={{float:'left'}} />
          <NavLink href="/" style={{float:'left', color: 'white'}}>상황판</NavLink>
        </NavItem>
        <NavItem >
            <img src={bird_move} style={{float:'left'}} />
          <NavLink href="/" style={{float:'left', color: 'white'}}>철새이동</NavLink>
        </NavItem>
    </Nav>
    */}

const styleMenu = { 
    marginLeft: '10px',
    float: 'left',
    fontSize: 'x-large',
    color:'#ffffff',
    allign: 'center',
    verticalAlign: 'middle'
}
const styleImg = { 
    marginLeft: '10px',
    float: 'left',
    fontSize: 'large',
    color:'#ffffff',
    allign: 'center',
    width: '28px',
    height: '28px',
    verticalAlign: 'middle'
} 

export default connect(store => ({
  app: store.app
}))(NavbarComponent);
