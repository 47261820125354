import React, { createContext, useState } from 'react';

//const AuthContext = createContext( {isSignin : false });

const AuthContext = createContext({
    state: { isSignin : false },
    actions: {
        setIsSignin: () => {}
    }
});

const AuthProvider = ({children}) => {
    const[isSignin, setIsSignin]  = useState(true);

    const value = {
        state: {isSignin}
        ,actions: {setIsSignin}
    };

    return (
        <AuthProvider.Provider value={value}>{children}</AuthProvider.Provider>
    );
};

export default AuthContext; 