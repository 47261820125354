import React, { useEffect, useState } from "react";
import AuthContext from '../../components/AuthContext';
import {
	Button,
	Card,
	CardBody
} from "reactstrap";

/*
sdf 융합연구단에 등록된 주소 /
http://123.213.1.196/supervise/sys/sysInfo.do?menuNo=1000000005&pageSize=10&page=1&sysCode=SYS0008&mode=U&searchSysNm=
*/

const SdfSso = () => {
	//const [isLogin, setIsLogin] = useState(false);
	//setIsLogin(true);
	const urlParams = new URLSearchParams(window.location.search);
	let data = {
		"accountId": urlParams.get('accountId'),
		"accountToken": urlParams.get('accountToken')
	}
	localStorage.setItem("sdfLoginData", JSON.stringify(data));
	window.location.href = "/";
	return (
		<React.Fragment>
			<div className="text-center mt-4">
				<h2>{global.serviceTitle}</h2>
				<p className="lead">이동 중...</p>
			</div>
		</React.Fragment>
	);
};

export default SdfSso;
