import React from "react";
import { connect } from "react-redux";
import "../assets/SDF/css/common.css";
import "../assets/SDF/css/style.css";

import {
	Button,
} from "reactstrap";

const NavbarComponent = ({ dispatch }) => {
	
	return (
		<div className="header">
			<div className="gnb">
				<div className="gnbMenu">
					<ul>
						<li id="topCreStatus"><a href="/oie/fmd/overview">발생현황</a></li>
						<li id="topInflowRisk" className="on"><a href="/epsd/epsdFmd">유입위험도</a></li>
						<li id="topCreData"><a href="/sdfData/fmd/datatable">발생데이터</a></li>
						{/* <li id="topCreTest"><a href="/sdfTest/fmd/test">Test</a></li> */}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default connect(store => ({
app: store.app
}))(NavbarComponent);
