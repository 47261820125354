import React from "react";

import Main from "../components/Main_NewLayout";
import SideNav from "../components/Sidebar_SDF";
import TopNav from "../components/Navbar_SDF";
import Gnb from "../components/Gnb_SDF";
import Wrapper from "../components/Wrapper";
import Content from "../components/Content";

const SDF = ({ children }) => {
    
    return (
        <React.Fragment>
            <TopNav />
            <Gnb />
            <Wrapper>
                <SideNav/>
                <Main>
                    <Content>{children}</Content>
                </Main>
            </Wrapper>
        </React.Fragment>
    );
}
export default SDF;
