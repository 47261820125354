let token = '';
let userInfo = JSON.parse(localStorage.getItem('userInfo'));
if(userInfo != null && userInfo != '') {
  token = userInfo.token; // localStorage에 저장된 token값
}

// routes.js의 변수(isSignin)에 true false으로 리턴하려고 했으나(동기방식으로 하지 않으면 오류) 인증들이 비동기식으로 되어 있어 현재 해당위치에 넣어둠.(유성연) 
export function chk() {
  //ex http://172.30.1.85:8080/kauth/api/sessionChk
  if(localStorage.getItem('sessionChk') == null){
    window.location.href="/auth/sign-out";
  }
  
  const url = global.signInUrl + "/sessionChk";
  fetch(url, {credentials: 'include'})
  .then(response => response.json())
  .then(modelAndView => {
    // alert(JSON.stringify(modelAndView));
    // 2020.10.19 크롬 브라우저에서 token이 상이하여 로그인 불가. 하지만 운영 혹은 IE에서는 정상동작함
    //alert('[' + (token == modelAndView.sessionID) + '] [' + (token === modelAndView.sessionID) + ']token=' + token + ', modelAndView.sessionID=' + modelAndView.sessionID);
    // 서버 sessionID와 local Storage token이 같을 경우 유지
    //http://172.30.1.85:8080/kauth/api/sessionChk
    //token --> http://172.30.1.250:8080/kware_auth/api/selectHpaiMenuList?id=admin&password=djemals1&userSe=GNR&siteGbn=rnd
    /*if(token === modelAndView.sessionID){  
    // 서버 sessionID와 local Storage token이 다를 경우 로그아웃  
    } else {
      if (global.service != 'sdf'){
        window.location.href='/auth/sign-out';
      }
  } */
  })
  .catch(error => console.log(error))
}