global.hostName = null;

if(window.location.href.indexOf('localhost') >= 0) {
	global.hostName = "로컬"
} else if(window.location.href.indexOf('172.30.1.250') >= 0) {
	global.hostName = "개발"
}

/******   데이터마트 api 서버 주소 - "HPAI 데이터 마트" svn://172.30.1.4/kware/rsch/hpai_dmrt/trunk  ******/
global.dmApiUrl = null;

/******   대시보드 api 서버 주소 - "해외 질병 발생 정보", "국내 질병 발생 정보..."  svn://172.30.1.4/kware/rsch/kware_rnd ******/
global.dbApiUrl = null;

/******   zeppelin api 서버 주소  ******/
//global.zeppelinApiUrl = "http://172.30.1.14:21004/";
//global.zeppelinApiUrl = "http://zp.kware.co.kr/";
global.zeppelinApiUrl = "http://hpai.kware.co.kr/zpapi/";

/******   svn 커밋 전 global.service 는 반드시 bird로 해주세요!!!!! ******/
global.service = 'rnd';

global.serviceTitle = "Data Analytics Plaform for Epidemiological Investigation";

/******  dev:개발pc, test:unit test, stg:개발서버, prod:운영서버  ******/
//기존 개발 서버: http://172.30.1.250:3000/
//철새 알림개발서버: http://http://172.30.1.250:3002/
let profile = 'prod';

switch (profile) {
	case 'dev':
		global.dmApiUrl       = 'http://172.30.1.250:8081/api1.0';
		global.downloadUrl    = 'http://172.30.1.250:8081';
		global.dbApiUrl       = 'http://172.30.1.250:8080';
		global.geoUrl         = 'http://172.30.1.14:10001';
		global.BingKey        = 'Agw_GaZE2Mum7vPnDhZh3rrXaY6jGklDnP9ia9s1MVIeeUeLyYAls5oj-6dH1We-';
		global.sdlApiUrl      = 'http://172.30.1.250:8081';
		global.signInUrl      = 'http://172.30.1.250:8080/kware_auth/api';
		global.brtAnalysisUrl = 'http://172.30.1.250:8081/api1.0';
		global.birdAlarm      = "http://172.30.1.250:8082/push/api0.1";
		break;
	case 'test':
		global.dmApiUrl       = 'http://172.30.1.250:8081/api1.0';
		global.downloadUrl    = 'http://172.30.1.250:8081';
		global.dbApiUrl       = 'http://172.30.1.250:8080';
		global.geoUrl         = 'http://172.30.1.14:10001';
		global.BingKey        = 'Agw_GaZE2Mum7vPnDhZh3rrXaY6jGklDnP9ia9s1MVIeeUeLyYAls5oj-6dH1We-';
		global.sdlApiUrl      = 'http://172.30.1.250:8081';
		global.signInUrl      = 'http://172.30.1.250:8080/kware_auth/api';
		global.brtAnalysisUrl = 'http://172.30.1.250:8081/api1.0';
		global.birdAlarm      = "http://172.30.1.250:8082/push/api0.1";
		break;
	case 'stg':
		global.dmApiUrl       = 'http://172.30.1.250:8081/api1.0';
		global.downloadUrl    = 'http://172.30.1.250:8081';
		global.dbApiUrl       = 'http://172.30.1.250:8080';
		global.geoUrl         = 'http://172.30.1.14:10001';
		global.BingKey        = 'Agw_GaZE2Mum7vPnDhZh3rrXaY6jGklDnP9ia9s1MVIeeUeLyYAls5oj-6dH1We-';
		global.sdlApiUrl      = 'http://172.30.1.250:8081';
		global.signInUrl      = 'http://172.30.1.250:8080/kware_auth/api';
		global.brtAnalysisUrl = 'http://172.30.1.250:8081/api1.0';
		global.birdAlarm      = "http://172.30.1.250:8082/push/api0.1";
		break;
	case 'prod':
		global.dmApiUrl       = 'http://hpai.kware.co.kr/dmartapi/api1.0';
		global.downloadUrl    = 'http://hpai.kware.co.kr/dmartapi';  //10084->172.30.1.13:8081
		global.dbApiUrl       = 'http://hpai.kware.co.kr/rndapi';  //15001->172.30.1.13:8080
		global.geoUrl         = 'http://hpai.kware.co.kr/geoapi';  //10001->172.30.1.13:10001
		global.BingKey        = 'Agw_GaZE2Mum7vPnDhZh3rrXaY6jGklDnP9ia9s1MVIeeUeLyYAls5oj-6dH1We-';
		global.sdlApiUrl      = 'http://hpai.kware.co.kr/dmartapi';
		global.signInUrl      = 'http://hpai.kware.co.kr/kware_auth/api';
		global.brtAnalysisUrl = 'http://hpai.kware.co.kr/dmartapi/api1.0';
		global.birdAlarm      = "http://hpai.kware.co.kr/push/api0.1";
		break;	
	default: ;
}
//global.service
switch(global.service) {
	case 'rnd':
		global.serviceTitle = "Data Analytics Plaform for Epidemiological Investigation";
		break;
	case 'bird':
		global.serviceTitle = "GIS 기반 통계분석 기술을 활용한 농장방역안내서비스";
		break;
	case 'cdr':
		global.serviceTitle = "고병원성 조류인플루엔자 전파 HPAI 역학지도 시스템";
		break;
	case 'qz':
		global.serviceTitle = 'GIS 기반 통계분석 기술을 활용한 방역지대설정지원시스템';
		break;
	case 'sdf':
		global.serviceTitle = '해외질병감시 시스템';
		break;
	case 'bird_alarm':
		global.serviceTitle = '철새 알리미 앱';
	case 'asf':
		global.serviceTitle = '아프리카돼지열병(ASF) 수입위험평가';
		break;
	case 'qia':
		global.serviceTitle = '지정검역물 수입위험평가시스템';
		break;
	default : ;
}

// 타이틀 바에 로컬/개발 표시
if(global.hostName) {
	global.serviceTitle = global.hostName + "-" + global.serviceTitle;
}

// 챠트 타입
let chartType = {};
chartType.BAR = 'BAR 이 값을 바로 쓰면 안되요';
chartType.DOUGHNUT = 'DOUGHNUT';
chartType.LINE = 'LINE';
chartType.PIE = 'PIE';
chartType.POLAR = 'POLAR';
chartType.RADAR = 'RADAR';

global.isSignIn = false;

/*
const aFetch = (url, options, isAsync) => {
	let response = null;

	if(isSync == true) {
		response = await fetch(url, options);
	} else {
		response =fetch(url, options);
	}
	return response;
  };
  */