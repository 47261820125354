import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";

const Main = ({ className, children, dispatch }) => {

  return(
  
    <div className={classNames("main", className)}>
      {/* <span
        className="sidebar-toggle d-flex mr-2"
        onClick={() => {
          dispatch(toggleSidebar());
        }}
      >
      <i className="hamburger align-self-center" />
      </span> */}
      {children}
    </div>
);

}

/* export default Main; */
export default connect(store => ({
  app: store.app
}))(Main);
