import React from "react";
import { connect } from "react-redux";
//import main_logo_new_2 from '../assets/img/main_logo_new_2.png'
import logo_bird from '../assets/img/Top_Logos_bird.png'
import logo_cdr from '../assets/img/Top_Logos_cdr.png'
import logo_qz from '../assets/img/Top_Logos_qz.png'
import logo_rnd from '../assets/img/Top_Logos_rnd.png'
import * as SessionChk from "../pages/auth/SessionChk";
import {
  Collapse,
  Navbar,
  Nav,
  NavbarBrand,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

import {
  Settings
} from "react-feather";

let logo_image = logo_rnd;
switch(global.service) {
	case 'rnd':
		logo_image = logo_rnd;
		break;
	case 'bird':
		logo_image = logo_bird;
		break;
	case 'cdr':
		logo_image = logo_cdr;
		break;
	case 'qz':
		logo_image = logo_qz;
		break;
	default : ;
}

const NavbarComponent = ({ dispatch }) => {
  SessionChk.chk();
  return (
    <Navbar color="#082a4f" light expand style={{
    background: "#082a4f",
    height: "100px"}}> 
      
       <NavbarBrand href="/" /* style={{width:"20vw"}} */>
        <img src={logo_image} /* style={{width:"20vw", resizeMode: 'cover'}} *//> {/* 브라우저 크기가 줄어들 시 같이 줄어들도록 css 수정 */}
        </NavbarBrand> 
            
        <Collapse navbar>
        <Nav className="mr-auto" navbar>
            {/* <NavItem >
                <img src={bird_move} style={{float:'left'}} />
              <NavLink href="/" style={{float:'left', color: 'white'}}>유입 감시</NavLink>
            </NavItem> */}
        </Nav>
        <Nav className="ml-auto" navbar>
          <UncontrolledDropdown nav inNavbar>
            <span className="d-inline-block d-sm-none">
              <DropdownToggle nav caret>
                <Settings size={18} className="align-middle" />
              </DropdownToggle>
            </span>
            <span className="d-none d-sm-inline-block">
              <DropdownToggle nav caret>
                <span className="text-light" style={{fontSize:"14px"}}>{JSON.parse(localStorage.getItem('userInfo')).name}</span>
              </DropdownToggle>
            </span>
            <DropdownMenu right>
              <DropdownItem onClick={() => {window.location.href='/auth/sign-out'}}>로그아웃</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default connect(store => ({
  app: store.app
}))(NavbarComponent);
