import * as types from "../constants";

const initialState = {
  isTwoDepthOpen: true,
  isSticky: false
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.SIDEBAR_TWODEPTH_VISIBILITY_TOGGLE:
      return {
        ...state,
        isTwoDepthOpen: !state.isTwoDepthOpen
      };
    case types.SIDEBAR_TWODEPTH_VISIBILITY_SHOW:
      return {
        ...state,
        isTwoDepthOpen: true
      };
    case types.SIDEBAR_TWODEPTH_VISIBILITY_HIDE:
      return {
        ...state,
        isTwoDepthOpen: false
      }; 

    default:
      return state;
  }
}
