import React from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import main_logo_new_2 from '../assets/SDF/images/logo.png'
import analysis from '../assets/img/icon/analysis.png'
import bird_move from '../assets/img/icon/bird_move.png'
import data_mart from '../assets/img/icon/data_mart.png'
import disease_info from '../assets/img/icon/disease_info.png'
import "../assets/SDF/css/common.css";
import "../assets/SDF/css/style.css";
import jQuery from "jquery";
import {
	Collapse,
	Navbar,
	Nav,
	NavbarBrand,
	NavItem,
	NavLink,
	Button,
} from "reactstrap";
import {
	Settings,
	User,
} from "react-feather";



const $ = jQuery;
let sdfLoginData = JSON.parse(localStorage.getItem("sdfLoginData"));


class NavbarComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			commAdiosUrl : "http://123.213.1.196",
			commSdfUrl : "http://123.213.1.197:8081"
		}
	}

	fn_logout = () => {
		window.localStorage.clear();
		window.sessionStorage.clear();
		window.location.href="http://123.213.1.196/";
	}

	fn_goSys = (searchSysCode) =>{
		$("form[name=switchSysFrm] input[name=searchSysCode]").val(searchSysCode);
		$("form[name=switchSysFrm] input[name=sysCode]").val(searchSysCode);
	
		if ($.trim(searchSysCode) == "") {
			alert("시스템을 선택하여 주십시오.");
			return;
		}
	
		var url = this.state.commAdiosUrl+"/cmmn/user/loginSys.do";
		switch (searchSysCode) {
			case 'SYS0001':	// 공통시스템
				url = this.state.commAdiosUrl+"/cmmn/user/loginSys.do";
				//url = "http://123.213.1.196/cmmn/sys/switchSystem.do"; 			
				break;
			case 'SYS0002':	// 출입차량
				url = this.state.commAdiosUrl+"/cmg/cmmn/user/loginSys.do";
				break;
			case 'SYS0003':	// 출입원
				url = this.state.commAdiosUrl+"/cmg/cmmn/user/loginSys.do";
				break;
			case 'SYS0004':	// 이상징후
				url = this.state.commAdiosUrl+"/anm/cmmn/user/loginSys.do";
				break;
			case 'SYS0005':	// 거점소독
				url = this.state.commSdfUrl+"/dis/api1.0/accounts/ssoLogin";
				break;
			case 'SYS0006':	// 현장진단
				url = this.state.commAdiosUrl+"/poct/user/loginSys.do";
				break;
			case 'SYS0007':	// 구제역확산차단서비스
				url = this.state.commAdiosUrl+"/fsbs/loginSys.do";
				break;
			case 'SYS0008':	// 해외질병
				url = this.state.commSdfUrl+"/dis/ovseaDissSsoGw";
				//url = "http://localhost:8081/ovseaDissSsoGw"; 
				break;
			default: ;
		}
		$("form[name=switchSysFrm] input[name=accountId]").val(sdfLoginData.accountId);
		$("form[name=switchSysFrm] input[name=accountToken]").val(sdfLoginData.accountToken);
		$("form[name=switchSysFrm]").attr("action", url);
		$("form[name=switchSysFrm]").submit();
	}
	componentDidMount(){
		if(sdfLoginData== null || sdfLoginData.accountId == null){
			alert("로그인이 필요합니다.");
			window.location.href= this.state.commAdiosUrl+"/cmmn/user/login.do?menuNo=1000000010";
			return false;
		}
		$.ajax({
			type: "GET",
			url: "http://123.213.1.197:8081/dis/api1.0/accounts/search/findByAccountId?accountId="+sdfLoginData.accountId,
			async: false,
			dataType: "json",
			contentType: "application/json",
			success: function (data) {
				$("#loginuserName").html(data.name+"님 접속");
				$.ajax({
					type: "GET",
					url: "http://123.213.1.197:8081/dis/api1.0/menu/topMenu?groupCode="+data.groupCode,
					async: false,
					dataType: "json",
					contentType: "application/json",
					success: function (data) {
						for (let i = 0; i < data.topMenuList.length; i++) {
							if (data.topMenuList[i].SYS_CODE == "SYS0001"){		//공통시스템
								$("#system-menu-SYS0001").css("display","block");
							}else if(data.topMenuList[i].SYS_CODE == "SYS0002"){//출입차량
								$("#system-menu-SYS0002").css("display","block");								
							}else if(data.topMenuList[i].SYS_CODE == "SYS0003"){//출입원
								$("#system-menu-SYS0003").css("display","block");
							}else if(data.topMenuList[i].SYS_CODE == "SYS0004"){//이상징후
								$("#system-menu-SYS0004").css("display","block");
							}else if(data.topMenuList[i].SYS_CODE == "SYS0005"){//거점소독시설
								$("#system-menu-SYS0005").css("display","block");
							}else if(data.topMenuList[i].SYS_CODE == "SYS0006"){//현장진단
								$("#system-menu-SYS0006").css("display","block");
							}else if(data.topMenuList[i].SYS_CODE == "SYS0007"){//구제역확산차단서비스
								$("#system-menu-SYS0007").css("display","block");
							}else if(data.topMenuList[i].SYS_CODE == "SYS0008"){//해외질병
								$("#system-menu-SYS0008").css("display","block");
							}
						}
					},
					error: function(err) {
						alert("실패");
					}
				})
			},
			error: function(err) {
				alert("실패");
				return false;
			}
		})
	}

	render(){
		return (
			<div className="header" style={{ background: "#FFFFFF" }} >
			<div className="top">
				<h1><a href="/"><img src={main_logo_new_2} alt="SDF 융합연구단 바이오시큐리티 통합정보"></img></a></h1>
				<div className="system-menu">
					<form id="switchSysFrm" name="switchSysFrm" method="post" target="_top">
						<input type="hidden" id="sysCode" name="sysCode" />
						<input type="hidden" id="searchSysCode" name="searchSysCode" />
						<input type="hidden" id="accountId" name="accountId"/>
						<input type="hidden" id="accountToken" name="accountToken"/>
					</form>
					<ul>
						<li><a style={{display: "none"}} onClick = { () => { this.fn_goSys('SYS0001'); } } id="system-menu-SYS0001">공통시스템</a></li>
						<li><a style={{display: "none"}} onClick = { () => { this.fn_goSys('SYS0002'); } } id="system-menu-SYS0002">출입차량</a></li>
						<li><a style={{display: "none"}} onClick = { () => { this.fn_goSys('SYS0003'); } } id="system-menu-SYS0003">출입원</a></li>
						<li><a style={{display: "none"}} onClick = { () => { this.fn_goSys('SYS0004'); } } id="system-menu-SYS0004">이상징후</a></li>
						<li><a style={{display: "none"}} onClick = { () => { this.fn_goSys('SYS0005'); } } id="system-menu-SYS0005">거점소독시설 전자소독필증 통합관리 시스템</a></li>
						<li><a style={{display: "none"}} onClick = { () => { this.fn_goSys('SYS0006'); } } id="system-menu-SYS0006">현장진단</a></li>
						<li><a style={{display: "none"}} onClick = { () => { this.fn_goSys('SYS0007'); } } id="system-menu-SYS0007">구제역확산차단서비스</a></li>
						<li><a style={{display: "none"}} onClick = { () => { this.fn_goSys('SYS0008'); } } id="system-menu-SYS0008" className="active">해외질병</a></li>
					</ul>
				</div>
				<div className="btn_info">
					<div className="member">
						<p>
							<span className="name" id="loginuserName"></span>
						</p>
					</div>
					<a onClick={this.fn_logout.bind(this)} className="logout">로그아웃</a>
				</div>
			</div>
		</div>
		);
	}
}

const styleMenu = {
	marginLeft: '10px',
	float: 'left',
	fontSize: 'x-large',
	color: '#ffffff',
	allign: 'center',
	verticalAlign: 'middle'
}
const styleImg = {
	marginLeft: '10px',
	float: 'left',
	fontSize: 'large',
	color: '#ffffff',
	allign: 'center',
	width: '28px',
	height: '28px',
	verticalAlign: 'middle'
}

export default NavbarComponent;