import React, {useContext} from "react";
import AuthContext from '../../components/AuthContext';

import {
  Button,
  Card,
  CardBody
} from "reactstrap";

function signOutChk() {
  //const signOutUrl = 'http://172.30.1.85:8080/kauth/api +'/logOut';
  const signOutUrl = global.signInUrl+'/logOut';

  fetch(signOutUrl, {credentials: 'include'})
  .then(response => response.json())
  .then(
    //console.log('success');
  )
  .catch(error => console.error('Error:', error))
}

const SignOut = () => {
  const authContext = useContext(AuthContext);
  authContext.isSignin = false;

  {signOutChk()}
  localStorage.clear();

  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h2>{global.serviceTitle}</h2>
        <p className="lead">로그아웃</p>
      </div>

      <Card>
        <CardBody>
          <div className="m-sm-4">
            로그아웃 되었습니다. 다시 접속하시려면 로그인 버튼을 클릭해주세요
            <a href="/"><Button className="float-right">로그인</Button></a>
          </div>
        </CardBody>
      </Card>
  </React.Fragment>
  );
};

export default SignOut;
