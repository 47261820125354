import React from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";

import { Badge, Collapse } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import { Box } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

// routes/index.js의 default 만 sidebar에 보이게 될 것임. notMenu 는 보이지 않음
import routes from "../routes/index";
import avatar from "../assets/img/avatars/avatar.jpg";
import { enableClassicTheme } from "../redux/actions/themeActions";
import { enableCorporateTheme } from "../redux/actions/themeActions";
import { enableModernTheme } from "../redux/actions/themeActions";

const SidebarCategory = withRouter(
  ({
    name,
    badgeColor,
    badgeText,
    icon: Icon,
    isOpen,
    children,
    onClick,
    location,
    to, 
    isMenu
  }) => {

    const getSidebarItemClass = path => {
      return location.pathname.indexOf(path) !== -1 ||
        (location.pathname === "/" && path === "/dashboard")
        ? "active"
        : "";
    };

    if(isMenu && isMenu == 'N') {
      return null;
    }
    
    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <span
          data-toggle="collapse"
          className={"sidebar-link " + (!isOpen ? "collapsed" : "")}
          onClick={onClick}
          aria-expanded={isOpen ? "true" : "false"}
        >
        { /* 메뉴 계층형구성시에 2depth이상 하위구성을 갖는 객체에서 
           아이콘을 설정하려고 할때 오류 발생 */
          Icon ? (
          <Icon size={18} className="align-middle mr-3" /> ):null
        }
          <span className="align-middle">{name}</span>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </span>
        <Collapse isOpen={isOpen}>
          <ul id="item" className={"sidebar-dropdown list-unstyled"}>
            {children}
          </ul>
        </Collapse>
      </li>
    );
  }
);

const SidebarItem = withRouter(
  ({ name, badgeColor, badgeText, icon: Icon, location, to, isMenu }) => {
    const getSidebarItemClass = path => {
      return location.pathname === path ? "active" : "";
    };
   
    if(isMenu && isMenu == 'N') {
      return null;
    }

    return (

        <li className={"sidebar-item " + getSidebarItemClass(to)}>
          <NavLink to={to} className="sidebar-link" activeClassName="active">
            {Icon ? <Icon size={18} className="align-middle mr-3" /> : null}
            {name}
            {badgeColor && badgeText ? (
              <Badge color={badgeColor} size={18} className="sidebar-badge">
                {badgeText}
              </Badge>
            ) : null}
          </NavLink>
        </li>

    );
  }
);

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  r_level = 0;
  r_key = 0;

  //child를 가지고 있는 route 정보만을 가지고 있음
  g_routes = {};
 
  //parent 값이 있어야 겠네.
  toggle = index => {

    let ar = [];
    let a = this.g_routes[index].pkey;
    while (a != null) {
      ar.push(a);
      a = this.g_routes[a].pkey;
    }
    ar.push(index);

    Object.keys(this.state).forEach(
      item => {
        var ok = false;
        for (var i = 0; i < ar.length; i++) {
          if (item == ar[i]) {
            ok = true;
            break;
          }
        }
        if (!ok) {
          this.state[item] = false;
          //rendering 1번만하면 좋지 않을까...
          /*
          this.setState(() => ({
            [item]: false
          }))
          */
        }
      }
    );

    // Toggle selected element
    this.setState(state => ({
      [index]: !state[index]
    }));

    /**
     * 동일한 state 값을 setState함수를 통해서 중복해서 변경하면 문제가 좀 있는듯..
     */
  };

  //계층형으로 Sidebar를 만들기위해서 Routes 자료에 key, pkey 추가하여 후에 컨트롤이 가능하도록 재귀함수 생성
  recursive_routes = (r_routes, pathName, pkey) => {
    if (r_routes instanceof Array) {
    } else {
      return;
    }
    this.r_level++;

    r_routes.forEach((r_route, index) => {

      r_route.pkey = pkey;
      r_route.key = this.r_key++;
      const isActive = pathName.indexOf(r_route.path) === 0;
      const isOpen = r_route.open;
      const isHome = r_route.containsHome && pathName === "/" ? true : false;

      if(isActive){
        //Active 즉 열려있는 상위 route를 오픈 상태로 설정한다. 다시렌더링하여 close되는 경우가 있음
        //react에서 url클릭하는데 Sidebar 클래스 신규생성되 항상 메뉴를 새로 구성한다. 토글 버튼은 문제없는데
        //이건 비효율적인데..
        if(pkey != null)
          this.state[pkey] = true;
      }

      if (r_route.children == null) {
        return;
      } else {
        /* 계속 rendering이 되는데 처음에 로딩되는 함수인데 setState를 써야 하나?? */
        /*
        this.setState(() => ({
          [r_route.key]: isActive || isOpen || isHome
        }));
        */
        this.state[r_route.key] = isActive || isOpen || isHome;

        this.g_routes[r_route.key] = r_route;
        this.recursive_routes(r_route.children, pathName, r_route.key);
      }
    });
    this.r_level--;
  };

  //렌터링역시 routes 자료를 계청형으로 표현하기 위해 재구함수 생성 
  recursive_routes_rendering = (category) => {
    return (
      category.children ? (
        <SidebarCategory
          name={category.name}
          badgeColor={category.badgeColor}
          badgeText={category.badgeText}
          icon={category.icon}
          to={category.path}
          isOpen={this.state[category.key]}
          isMenu={category.isMenu}
          onClick={() => {this.toggle(category.key)}}
        >
          {category.children.map((route, index) => {
            return this.recursive_routes_rendering(route);
          }

          )}
        </SidebarCategory>
      ) : (
          <SidebarItem
            key={category.key}
            name={category.name}
            to={category.path}
            icon={category.icon}
            badgeColor={category.badgeColor}
            badgeText={category.badgeText}
            isMenu={category.isMenu}
          />
        )
    );
  };

  componentWillMount() {
    /* Open collapse element that matches current url */
    const pathName = this.props.location.pathname;

    this.recursive_routes(routes, pathName, null);

    switch(global.service) {
      case 'bird':
        enableClassicTheme();
        break;
      case 'cdr':
        enableCorporateTheme();
        break;
      case 'qz':
        enableModernTheme();
        break;
      default : ;
    }
  }

  render() {
    const { sidebar, layout } = this.props;
    const title= global.serviceTitle;
    var rtnObj = (
      <nav
        className={
          "sidebar" +
          (!sidebar.isOpen ? " toggled" : "") +
          (sidebar.isSticky ? " sidebar-sticky" : "")
        }
      >
        <div className="sidebar-content">
          <PerfectScrollbar>
            <a className="sidebar-brand" href="/">
              <Box className="align-middle text-primary" size={24} />{" "}
              <span className="align-middle" style={{fontSize: "0.96rem"}}>
                {title}
              </span>
            </a>

            <ul className="sidebar-nav">
              {routes.map((category, index) => {
                if(category.name != null && category.name != "") {
                  return (
                    <React.Fragment key={category.key}>
                      {category.header ? (
                        <li className="sidebar-header">{category.header}</li>
                      ) : null}
                      {/*위에서 함수를 순환(recursive) 처리해서 결과물을 렌더링*/}
                      {this.recursive_routes_rendering(category)}
                    </React.Fragment>
                  );
                }
              })}
            </ul>

            {false && !layout.isBoxed && !sidebar.isSticky ? (
              <div className="sidebar-bottom d-none d-lg-block">
                <div className="media">
                  <img
                    className="rounded-circle mr-3"
                    src={avatar}
                    alt="Chris Wood"
                    width="40"
                    height="40"
                  />
                  <div className="media-body">
                    <h5 className="mb-1">Chris Wood</h5>
                    <div>
                      <FontAwesomeIcon
                        icon={faCircle}
                        className="text-success"
                      />{" "}
                      Online
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </PerfectScrollbar>
        </div>
      </nav>
    );

    return rtnObj;
  }
}

export default withRouter(
  connect(store => {
    return {
      sidebar: store.sidebar,
      layout: store.layout
    };
  }
  )(Sidebar)
);
