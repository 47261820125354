import React, { useEffect, useState } from "react";

import Main from "../components/Main_NewLayout";
import Sidebar from "../components/Sidebar";
import TopNav from "../components/Navbar_NewLayout";
import Wrapper from "../components/Wrapper";
import Content from "../components/Content";


//let menuList = JSON.parse(localStorage.getItem('menuList'));  /epsd/Epsd /epsd/epsdAsf  /epsd/epsdFmd

const TopMenu = ({ children }) => {
    const [contents, setContents] = useState(null);
    //console.log("children",children.props.location.pathname)
    useEffect(()=>{
        const path = children.props.location.pathname;
        switch (path) {
            case '/topMenu/dynamicsMap':
            case '/dynamicsMap/EpidZone':
            case '/epsd/epsdFmd':
            case '/epsd/epsdAsf':
            case '/epsd/Epsd': setContents(children); break;
            default: setContents(<Content>{children}</Content>); break;
        }
    }, [])
    return (
        <React.Fragment>
            <TopNav />
            <Wrapper>
                <Sidebar/>
                <Main>
                    {contents}
                </Main>
            </Wrapper>
        </React.Fragment>
    );
}
export default TopMenu;

