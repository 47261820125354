import React from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { Badge} from "reactstrap";
import { Box } from "react-feather";
import routes from "../routes/index";
import "../assets/SDF/css/common.css";
import "../assets/SDF/css/style.css";
import PerfectScrollbar from "react-perfect-scrollbar";

/* const SidebarCategory = withRouter(
({
	icon: Icon,
	location,
	children,
	badgeText,
	to, 
    isMenu
}) => {
	const getSidebarItemClass = path => {
	return location.pathname.indexOf(path) !== -1 || (location.pathname === "/" && path === "/dashboard")
		? "on"
		: "";
	};
	console.log(getSidebarItemClass(to))
	if(isMenu && isMenu == 'N') {
		return null;
	  }

	return (
		<li className={getSidebarItemClass(to)}>
			<a href={to}>{badgeText}</a>
			<ul>
				{children}
			</ul>
		</li>
		);
	}
);

const SidebarItem = withRouter(
({ name, badgeColor, badgeText, icon: Icon, location, to, isHidden, isMenu }) => {
	const getSidebarItemClass = path => {
	return location.pathname === path ? "on" : "";
	};

	if(isMenu && isMenu == 'N') {
		return null;
	  }

	// TODO 이런식으로 하드코딩하면 안되지만 일단 넘어가자
	return (
		<li className={getSidebarItemClass(to)}><a href={to}>{name}</a>
		</li>
	);
}
); */

class Sidebar extends React.Component {
constructor(props) {
	super(props);
	this.state = {};
}

toggle = index => {
	// Collapse all elements
	Object.keys(this.state).forEach(
	item =>
		this.state[index] ||
		this.setState(() => ({
		[item]: false
		}))
	);

	// Toggle selected element
	this.setState(state => ({
	[index]: !state[index]
	}));
};

componentWillMount() {
	/* Open collapse element that matches current url */
	const pathName = this.props.location.pathname;

	routes.forEach((route, index) => {
		const isActive = pathName.indexOf(route.path) === 0;
		const isOpen = route.open;
		const isHome = route.containsHome && pathName === "/" ? true : false;

		this.setState(() => ({[index]: isActive || isOpen || isHome}));
	});

}
componentDidMount(){

	console.log("window.location.pathname.split('/')[1]",window.location.pathname.split('/')[1]);

	if(window.location.pathname == "/epsd/epsdFmd" || window.location.pathname == "/epsd/epsdAsf" ||  window.location.pathname == "/brt/BrtAnalysis" || window.location.pathname == "/brt/BrtDetailAnalysis"  || window.location.pathname == "/lr/LrAnalysis" || window.location.pathname == "/lr/LrDetailAnalysis" || window.location.pathname == "/gwpr/GwprAnalysis" || window.location.pathname == "/gwpr/GwprDetailAnalysis"){
		//inflowRisk 유입위험도
		//creStatus 발생현황
		//creData 발생데이터
		//좌측 메뉴 선택에 따라 안보이게 하기. 
		document.getElementById("creStatus").style.display='none';
		document.getElementById("creData").style.display='none';
		document.getElementById("creTest").style.display='none';
		document.getElementById("menuText").innerHTML="유입위험도"
		//최상단 메뉴 class on 추가하여 선택 css 볁경하기.
		document.getElementById("topInflowRisk").classList.add("on")
		document.getElementById("topCreStatus").classList.remove("on")
		document.getElementById("topCreData").classList.remove("on")

		//좌측 2뎁스 메뉴 CSS 클릭시 변경 (현재 유입위험도 분석모델 페이지 정해지지 않아 주석처리)
		if(window.location.pathname == "/epsd/epsdFmd"){
			document.getElementById("FMDEpsd").classList.add("on")
			document.getElementById("ASFEpsd").classList.remove("on")
			document.getElementById("BrtAnalysis").classList.remove("on")
			document.getElementById("LrAnalysis").classList.remove("on")
			document.getElementById("GwprAnalysis").classList.remove("on")
			//document.getElementById("analysisModel").classList.remove("on")
		}else if(window.location.pathname == "/epsd/epsdAsf"){
			document.getElementById("ASFEpsd").classList.add("on")
			document.getElementById("FMDEpsd").classList.remove("on")
			document.getElementById("BrtAnalysis").classList.remove("on")
			document.getElementById("LrAnalysis").classList.remove("on")
			document.getElementById("GwprAnalysis").classList.remove("on")
			//document.getElementById("analysisModel").classList.add("on")
		}else if(window.location.pathname == "/brt/BrtAnalysis" || window.location.pathname == "/brt/BrtDetailAnalysis"){
			document.getElementById("BrtAnalysis").classList.add("on")
			document.getElementById("FMDEpsd").classList.remove("on")
			document.getElementById("ASFEpsd").classList.remove("on")
			document.getElementById("LrAnalysis").classList.remove("on")
			document.getElementById("GwprAnalysis").classList.remove("on")
		}else if(window.location.pathname == "/lr/LrAnalysis" || window.location.pathname == "/lr/LrDetailAnalysis"){
			document.getElementById("LrAnalysis").classList.add("on")
			document.getElementById("FMDEpsd").classList.remove("on")
			document.getElementById("ASFEpsd").classList.remove("on")
			document.getElementById("BrtAnalysis").classList.remove("on")
			document.getElementById("GwprAnalysis").classList.remove("on")
		}else if(window.location.pathname == "/gwpr/GwprAnalysis" || window.location.pathname == "/gwpr/GwprDetailAnalysis"){
			document.getElementById("GwprAnalysis").classList.add("on")
			document.getElementById("FMDEpsd").classList.remove("on")
			document.getElementById("ASFEpsd").classList.remove("on")
			document.getElementById("BrtAnalysis").classList.remove("on")
			document.getElementById("LrAnalysis").classList.remove("on")
		}
		
	}else if(window.location.pathname == "/oie/asf/overview" || window.location.pathname == "/oie/fmd/overview" || window.location.pathname.split('/')[1] == "oie"){
		//좌측 메뉴 선택에 따라 안보이게 하기.
		document.getElementById("inflowRisk").style.display='none';
		document.getElementById("creData").style.display='none';
		document.getElementById("creTest").style.display='none';
		document.getElementById("menuText").innerHTML="발생현황"
		//최상단 메뉴 class on 추가하여 선택 css 볁경하기.
		document.getElementById("topInflowRisk").classList.remove("on")
		document.getElementById("topCreStatus").classList.add("on")
		document.getElementById("topCreData").classList.remove("on")
		//좌측 2뎁스 메뉴 CSS 클릭시 변경
		if(window.location.pathname == "/oie/fmd/overview" || window.location.pathname.split('/')[2] == "fmd"){
			document.getElementById("FMDOverview").classList.add("on")
			document.getElementById("ASFOverview").classList.remove("on")
		}else if(window.location.pathname == "/oie/asf/overview" || window.location.pathname.split('/')[2] == "asf"){
			document.getElementById("FMDOverview").classList.remove("on")
			document.getElementById("ASFOverview").classList.add("on")
		}
	}else if(window.location.pathname == "/sdfData/asf/datatable" || window.location.pathname == "/sdfData/fmd/datatable" || window.location.pathname == "/importWeight/importList"){
		//좌측 메뉴 선택에 따라 안보이게 하기.
		document.getElementById("inflowRisk").style.display='none';
		document.getElementById("creStatus").style.display='none';
		document.getElementById("creTest").style.display='none';
		document.getElementById("menuText").innerHTML="발생데이터"
		//최상단 메뉴 class on 추가하여 선택 css 변경하기.
		document.getElementById("topInflowRisk").classList.remove("on")
		document.getElementById("topCreStatus").classList.remove("on")
		document.getElementById("topCreData").classList.add("on")
		//좌측 2뎁스 메뉴 CSS 클릭시 변경
		if(window.location.pathname == "/sdfData/fmd/datatable"){
			document.getElementById("FMDDatatable").classList.add("on")
			document.getElementById("ASFDatatable").classList.remove("on")
			document.getElementById("importList").classList.remove("on")
		}else if(window.location.pathname == "/sdfData/asf/datatable"){ 
			document.getElementById("ASFDatatable").classList.add("on")
			document.getElementById("FMDDatatable").classList.remove("on")
			document.getElementById("importList").classList.remove("on")
		}else if(window.location.pathname == "/importWeight/importList"){
			document.getElementById("importList").classList.add("on")
			document.getElementById("ASFDatatable").classList.remove("on")
			document.getElementById("FMDDatatable").classList.remove("on")
			
		}
	}else if(window.location.pathname == "/sdfTest/asf/test" || window.location.pathname == "/sdfTest/fmd/test"){
		//좌측 메뉴 선택에 따라 안보이게 하기.
		document.getElementById("inflowRisk").style.display='none';
		document.getElementById("creStatus").style.display='none';
		document.getElementById("creData").style.display='none';
		document.getElementById("menuText").innerHTML="Test"
		//최상단 메뉴 class on 추가하여 선택 css 볁경하기.
		document.getElementById("topInflowRisk").classList.remove("on")
		document.getElementById("topCreStatus").classList.remove("on")
		document.getElementById("topCreData").classList.remove("on")
		document.getElementById("topCreTest").classList.add("on")
		//좌측 2뎁스 메뉴 CSS 클릭시 변경
		if(window.location.pathname == "/sdfTest/fmd/test"){
			document.getElementById("ASFTest").classList.remove("on")
			document.getElementById("FMDTest").classList.add("on")
		}else if(window.location.pathname == "/sdfTest/asf/test"){
			document.getElementById("ASFTest").classList.add("on")
			document.getElementById("FMDTest").classList.remove("on")
		}
	}

}
//현재 유입위험도는 임의로 page 해놓음.(분석모델 EPSD로)
render() {
	const { sidebar, layout } = this.props;

	return (
		<nav className={"sidebar" +(!sidebar.isOpen ? " toggled" : "") +(sidebar.isSticky ? " sidebar-sticky" : "")} style={{background:"#FFFFFF",marginTop:"1px"}}>
        <div className="sidebar-content">
			<PerfectScrollbar style={{background:"#FFFFFF"}}>
			<a className="sidebar-brand" style={{background:"#144885"}} >
				<h2 className="lnb-tit" ><span id="menuText" style={{color:"#FFFFFF"}}>유입위험도</span></h2>
            </a>
			<ul className="sidebar-nav lnb-menu" style={{background:"#FFFFFF"}}>
				{/* 테스트2 시작 */}
			{/* 	{routes.map((category, index) => {
					return (
						<React.Fragment key={index}>
						{category.children ? (
							<SidebarCategory
								badgeText={category.name}
								to={category.path}
								isMenu={category.isMenu}
								>

						{category.children.map((route, index) => (
						<SidebarItem
							key={index}
							to={route.path}
							name={route.name}
							isMenu={route.isMenu}
                        />
                        ))}
							</SidebarCategory>
						) : (
							<li className="on"><a href={category.path}>{category.name}</a></li>
						)}
						</React.Fragment>
					)
				})} */}
				{/* 테스트2 끝 잘 동작 className on 받아오는 부분 테스트 필요 */}

				<li className="on" id="inflowRisk">
					<a href="/epsd/epsdFmd">유입위험도</a>
					<ul>
						<li className="on" id="FMDEpsd"><a href="/epsd/epsdFmd">FMD Epsd</a></li>
						<li id="ASFEpsd" style={{display : "none"}}><a href="/epsd/epsdAsf">ASF Epsd</a></li>
					</ul>
					<a href="/brt/BrtAnalysis" id="analysisModel">분석모델</a>
					<ul>
						<li id="BrtAnalysis"><a href="/brt/BrtAnalysis">BRT</a></li>
						<li id="LrAnalysis"><a href="/lr/LrAnalysis">LR</a></li>
						<li id="GwprAnalysis"><a href="/gwpr/GwprAnalysis">GWPR</a></li>
					</ul>
				</li>
				<li id="creStatus">
					<a href="/oie/fmd/overview">발생현황</a>
					<ul>
						<li id="FMDOverview"><a href="/oie/fmd/overview">FMD overview</a></li>
						<li id="ASFOverview"><a href="/oie/asf/overview">ASF overview</a></li>
					</ul>
				</li>
				<li id="creData">
					<a href="/sdfData/fmd/datatable">발생데이터</a>
					<ul>
						<li id="FMDDatatable"><a href="/sdfData/fmd/datatable">FMD datatable</a></li>
						<li id="ASFDatatable"><a href="/sdfData/asf/datatable">ASF datatable</a></li>
					</ul>
					<a href="/importWeight/importList">수입량</a>
					<ul>
						<li id="importList"><a href="/importWeight/importList">수입량 리스트</a></li>
					</ul>
				</li>
				<li id="creTest">
					<a href="/sdfData/fmd/test">Test</a>
					<ul>
						<li id="FMDTest"><a href="/sdfTest/fmd/test">FMD test</a></li>
						<li id="ASFTest"><a href="/sdfTest/asf/test">ASF test</a></li>
					</ul>
				</li>
			</ul>
		</PerfectScrollbar>
		</div>
	</nav>

	);
}
}

export default withRouter(
connect(store => ({
	sidebar: store.sidebar,
	layout: store.layout
}))(Sidebar)
);
